<template>
    <router-view></router-view>
</template>

<style>
    /* 系统初始化 */
    @import '@/assets/css/init';
    /* element框架 */
    @import '@/assets/css/frame';
    /* 网站样式 */
    @import '@/assets/css/web';
    /* 阿里图标 */
    @import '@/assets/css/iconfont';
</style>